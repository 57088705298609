// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/gv023136/apps/pen-and-paper-site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("/Users/gv023136/apps/pen-and-paper-site/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-generator-js": () => import("/Users/gv023136/apps/pen-and-paper-site/src/pages/generator.js" /* webpackChunkName: "component---src-pages-generator-js" */),
  "component---src-pages-index-js": () => import("/Users/gv023136/apps/pen-and-paper-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paper-resources-js": () => import("/Users/gv023136/apps/pen-and-paper-site/src/pages/paper-resources.js" /* webpackChunkName: "component---src-pages-paper-resources-js" */)
}

